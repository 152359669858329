import { NewsProvider } from "../../contexts/newsContext";
import Banner from "../../components/Banner";
import Hero from "../../components/Hero";
import LastNews from "../../components/LastNews";
import MainLayout from "../../layouts/MainLayout";
import { SettingsContext } from "../../contexts/settingsContext";
import { useContext } from "react";
import Newsletter from "../../components/Newsletter";

function Home() {
   const { settings } = useContext(SettingsContext);

   return (
      <MainLayout pageTitle="Accueil" className="home">
         <Banner images={settings.homeBanner} showBioLogo={true} />
         <Hero
            text={settings.homeHero}
            buttons={[{ title: "Nos produits", url: "/produits", variant: "outline-primary" }, { title: "Qui sommes-nous", url: "/qui-sommes-nous" }]}
         />
         <NewsProvider>
            <LastNews />
         </NewsProvider>
         <Newsletter />
      </MainLayout>
   );
}

export default Home;
