import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import AboutUs from "./pages/AboutUs";
import News from "./pages/News";
import NewsArticle from "./pages/NewsArticle";
import Contact from "./pages/Contact";
import Account from "./pages/Account";
import Cart from "./pages/Cart";
import ProductInfo from "./pages/ProductInfo";
import Policy from "./pages/Policy";
import ErrorPage from "./pages/ErrorPage";
import Search from "./pages/Search";
import Legal from "./pages/Legal";
import AccountCreation from "./pages/AccountCreation";
import AccountConfirmation from "./pages/AccountConfirmation";
import AdminSettings from "./pages/AdminSettings";
import AdminOrders from "./pages/AdminOrders";
import OrderDetails from "./pages/OrderDetails";
import { NewsContextLayout } from "./contexts/newsContext";
import AccountReinitialisation from "./pages/AccountReinitialisation";
import { ProductContextLayout } from "./contexts/productContext";
import ProductCategories from "./pages/ProductCategories";
import AdminCustomers from "./pages/AdminCustomers";
import { SettingsContext } from "./contexts/settingsContext";
import Loader from "./components/Loader";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import AdminNewsletter from "./pages/AdminNewsletter";

function GeneralContextLayout() {
   const { isLoadingSettings } = useContext(SettingsContext)
   return isLoadingSettings ? <Loader variant="page" text="Chargement en cours" /> : <Outlet />
}

function Router() {
   return (
      <BrowserRouter>
         <Routes>
            <Route element={<GeneralContextLayout />} >
               <Route path="/" element={<Home />} />
               <Route element={<ProductContextLayout />}>
                  <Route path="/produits" element={<ProductCategories />} />
                  <Route path="/produits/:categoryName" element={<Products />} />
                  <Route path="/produits/:categoryName/:productId" element={<ProductInfo />} />
               </Route>
               <Route path="/viande-bovine" element={<Navigate to="/produits/viande-bovine" replace /> }/>
               <Route path="/conserverie" element={<Navigate to="/produits/conserves" replace /> }/>
               <Route path="/boulangerie-patisserie" element={<Navigate to="/produits/boulangerie-patisserie" replace /> }/>
               <Route path="/viande-bovine/les-morceaux-de-veau" element={<Navigate to="/produits/viande-bovine" replace /> }/>
               <Route path="/viande-bovine/les-morceaux-de-boeuf" element={<Navigate to="/produits/viande-bovine" replace /> }/>
               <Route path="/qui-sommes-nous" element={<AboutUs />} />
               <Route path="/qui-sommes-nous/:aboutPageName" element={<AboutUs />} />
               <Route element={<NewsContextLayout />}>
                  <Route path="/actualites" element={<News />} />
                  <Route path="/actualites/:newsId" element={<NewsArticle />} />
               </Route>
               <Route path="/actualités" element={<Navigate to="/actualites" replace /> }/>
               <Route path="/contact" element={<Contact />} />
               <Route path="/rechercher" element={<Search />} />
               <Route path="/compte" element={<Account />} />
               <Route path="/compte/reservations/:orderId" element={<OrderDetails />} />
               <Route path="/compte/creation" element={<AccountCreation />} />
               <Route path="/compte/confirmation/:token" element={<AccountConfirmation />} />
               <Route path="/compte/reinitialisation/:token" element={<AccountReinitialisation />} />
               <Route path="/panier" element={<Cart />} />
               <Route path="/conditions-generales" element={<Policy />} />
               <Route path="/mentions-legales" element={<Legal />} />
               <Route path="/mentions-légales" element={<Navigate to="/mentions-legales" replace /> }/>
               <Route path="/admin/parametres" element={<AdminSettings />} />
               <Route path="/admin/clients" element={<AdminCustomers />} />
               <Route path="/admin/reservations" element={<AdminOrders />} />
               <Route path="/admin/reservations/:orderId" element={<OrderDetails />} />
               <Route path="/admin/newsletter" element={<AdminNewsletter />} />
               <Route path="*" element={<ErrorPage />} />
            </Route>
         </Routes>
      </BrowserRouter>
   );
}

export default Router;
