import { Link } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";

function Legal() {
   const pageTitle = "Mentions légales";
   return (
      <PageLayout pageTitle={pageTitle} className="legal" breadCrumbItems={[{ title: pageTitle }]}>
         <h1 className="page-title">{pageTitle}</h1>
         <div className="legal__content">
            <h2>Editeur</h2>
            <p>
               Ferme du Trèfle Blanc
               <br />1 village de Massigné
               <br />
               44240 La Chapelle-sur-Erdre
               <br />
               France
               <br />
               Email : veuillez utiliser notre <Link to="/contact">formulaire de contact</Link>
               <br />
               Téléphone : +33 (0)2 55 11 94 66
               <br />
               <br />
               <strong>
                  Attention : cette adresse ne reçoit pas de public. Merci de vous rendre au magasin
                  6 route du Saz à La Chapelle-sur-Erdre.
               </strong>
            </p>
            <h2>Identification</h2>
            <p>
               SIREN : 410352801
               <br />
               RCS : Nantes D 410 352 801
               <br />
               N° TVA intracommunautaire : FR84410352801
               <br />
               Code APE : 0141Z
            </p>
            <h2>Magasin du Trèfle Blanc</h2>
            <p>
               SARL au capital de 3 600 €
               <br />
               SIREN : 930125786
               <br />
               RCS : Nantes B 930 125 786
               <br />
               N° TVA intracommunautaire : FR01930125786
               <br />
               Code APE : 4711B
            </p>
            <h2>Conception et réalisation</h2>
            <p>
               Maxime ROBIN & Jean-Baptiste ZEDDA
               <br />
               <Link to="https://www.maximerobindev.com" target="_blank">
                  www.maximerobindev.com
               </Link>
            </p>
            <h2>Crédit photos</h2>
            <p>
               Logo conçu par Karine ROUILLARD
               <br />
               Images d'illustration par KamranAydinov, Freepik
            </p>
            <h2>Hébergement</h2>
            <p>
               o2switch SAS
               <br />
               Chemin des Pardiaux
               <br />
               63000 Clermont-Ferrand
               <br />
               France
               <br />
               Téléphone : +33 (0)4 44 44 60 40
            </p>
         </div>
      </PageLayout>
   );
}

export default Legal;
