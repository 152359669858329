import { UserContext } from "../../contexts/userContext";
import { useContext } from "react";
import PageLayout from "../../layouts/PageLayout";
import ErrorBlock from "../../components/ErrorBlock";
import { Link } from "react-router-dom";


function AdminNewsletter() {
    const pageTitle = "Newsletter";
    const { user } = useContext(UserContext);

    if ((user && !user.isAdmin) || user === null) {
        return (
            <PageLayout
                pageTitle={pageTitle}
                className="admin-newsletter"
                breadCrumbItems={[{ title: pageTitle }]}
            >
                <ErrorBlock code={403} />
            </PageLayout>
        );
    } 

    return (
        <PageLayout
            pageTitle={pageTitle}
            className="admin-newsletter"
            breadCrumbItems={[{ title: pageTitle }]}
        >
            <h1 className="page-title">{pageTitle}</h1>
            <div className="white-block">
                <h2>Mode d'emploi</h2>
                <ol>
                    <li>Se connecter sur la <Link to="https://www.fermedutrefleblanc.fr/newsletter/admin/" target="_blank" rel="noopener noreferrer">page d'administration des newsletters</Link></li>
                    <li>Dans le menu latéral, choisir "Campagnes" puis "Envoyer une campagne"</li>
                    <li>Renseigner le "Sujet de la campagne" (= objet du mail)</li>
                    <li>Taper le message désiré dans "Composition du message"</li>
                    <li>Dans l'onglet supérieur n°4 "Listes", choisir la ou les listes auxquelles envoyer le message<br/>
                        (note: la liste "Vente de viande" correspond à la liste historique utilisée par Blandine, alors que "Newsletter générale" correspond aux utilisateurs du site web)</li>
                    <li>Dans l'onglet supérieur n°5, cliquer sur le sur bouton "Mettre la campagne en file d'attente"</li>
                    <li>NE RIEN FAIRE D'AUTRE, les messages s'enverront tout seul</li>
                </ol>
            </div>
            <div className="white-block">
                <h2>Statistiques</h2>
                <div>Le menu latéral contient une section Statistiques qui permet de voir les statistiques d'une campagne comme le nombre de messages ouverts, le nombre de clics, etc...</div>
            </div>
        </PageLayout>
    )

}

export default AdminNewsletter;
