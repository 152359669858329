import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { SettingsContext } from "../../contexts/settingsContext";
import { CartContext } from "../../contexts/cartContext";
import { UserContext } from "../../contexts/userContext";
import { useContext, useState, useEffect, useMemo, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faUser,
   faBasketShopping,
   faGear,
   faCalendarDays,
   faBars,
   faMagnifyingGlass,
   faUserGroup,
   faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { formatDate, formatRange, formatJsonDate, formatJsonTime, groupedBy } from "../../helpers";
import AlertBox from "../AlertBox";
import OpeningHours from "../OpeningHours";
import CloseButton from "../CloseButton";

function Header() {
   const { categories, aboutPages, isClosed, settings } = useContext(SettingsContext);
   const { setCartModalIsOpen, cartItemsNumber } = useContext(CartContext);
   const { user } = useContext(UserContext);
   const [headerClass, setHeaderClass] = useState("");
   const [activeMenu, setActiveMenu] = useState();
   const [burgerIsOpen, setBurgerIsOpen] = useState(false);
   const [showOpeningModal, setShowOpeningModal] = useState(false);
   const location = useLocation();
   const navigate = useNavigate();
   const headerRef = useRef();
   const submenuRef = useRef();
   const mainmenuRef = useRef();

   useEffect(() => {
      const headerH = headerRef.current.getBoundingClientRect().height;
      document.body.style.paddingTop = headerH + "px";
   }, [user, isClosed]);

   useEffect(() => {
      const handleScroll = () => {
         // Ajoute une ombre quand on scroll la page
         setHeaderClass(window.scrollY > 25 ? "header--sticky" : "");
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll);
   }, [])

   useEffect(() => {
      if (mainmenuRef && submenuRef) {
         // Récupère la position basse dans le DOM du menu principal
         const bottomPos = mainmenuRef.current.getBoundingClientRect().bottom;
         // affecte la position basse du menu principal en tant que position haute du sous-menu
         submenuRef.current.style.top = bottomPos + "px";
      }
   }, [activeMenu]);

   useEffect(() => {
      document.body.style.overflow = burgerIsOpen ? "hidden" : "auto";
   }, [burgerIsOpen]);

   const specialDays = useMemo(() => {
      if (!settings.Hours) return {};
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      const filtered = settings.Hours?.filter(
         (hour) => hour.type === 0 && hour.temporary && new Date(hour.temporaryDate) >= now,
      );
      return groupedBy(filtered, "temporaryDate");
   }, [settings]);

   return (
      <header ref={headerRef} className={`header ${headerClass}`} onMouseLeave={() => setActiveMenu()}>
         <div ref={mainmenuRef} className="header__main ">
            {user?.isAdmin && (
               <div className="header__admin">
                  <div className="page-container">
                     <p>MODE ADMINISTRATEUR</p>
                     <div className="header__admin-buttons">
                        <Button
                           variant="secondary"
                           size="sm"
                           onClick={() => navigate("/admin/parametres")}
                        >
                           <FontAwesomeIcon icon={faGear} />
                           <span>Paramètres</span>
                        </Button>
                        <Button
                           variant="secondary"
                           size="sm"
                           onClick={() => navigate("/admin/reservations")}
                        >
                           <FontAwesomeIcon icon={faBasketShopping} />
                           <span>Réservations</span>
                        </Button>
                        <Button
                           variant="secondary"
                           size="sm"
                           onClick={() => navigate("/admin/clients")}
                        >
                           <FontAwesomeIcon icon={faUserGroup} />
                           <span>Clients</span>
                        </Button>
                        <Button
                           variant="secondary"
                           size="sm"
                           onClick={() => navigate("/admin/newsletter")}
                        >
                           <FontAwesomeIcon icon={faEnvelope} />
                           <span>Newsletter</span>
                        </Button>
                     </div>
                  </div>
               </div>
            )}
            {(isClosed || Object.keys(specialDays).length > 0) && (
               <div className="header__closed">
                  <div className="page-container">
                     <FontAwesomeIcon icon={faCalendarDays} />
                     <div>
                        {isClosed && (
                           <p>
                              Magasin fermé {formatRange(settings.closingStart, settings.closingEnd)}.
                           </p>
                        )}
                        {Object.keys(specialDays).length > 0 && (
                           <p>
                              Horaires exceptionnels le{" "}
                              {Object.keys(specialDays)
                                 .map((dayKey) => formatJsonDate(dayKey + "T00:00:00.000Z", true))
                                 .join(", ")}
                              .
                           </p>
                        )}
                     </div>
                     <Button
                        size="sm"
                        variant="light"
                        className="btn-rounded"
                        onClick={() => setShowOpeningModal(true)}
                     >
                        + INFOS
                     </Button>
                  </div>
                  <AlertBox
                     text={
                        <>
                           {isClosed && (
                              <>
                                 <h4>Fermeture du magasin</h4>
                                 <p>
                                    Magasin fermé {formatRange(settings.closingStart, settings.closingEnd)}.<br />
                                    Retraits possibles à partir du{" "}
                                    {formatDate(settings.closingEnd, true, false, false, true, 1)}.
                                 </p>
                                 <br />
                              </>
                           )}
                           {Object.keys(specialDays).length > 0 && (
                              <>
                                 <h4>Horaires exceptionnels</h4>
                                 {Object.keys(specialDays).map((dayKey, index) => (
                                    <p key={`specialday-${index}`}>
                                       {formatJsonDate(
                                          dayKey + "T00:00:00.000Z",
                                          false,
                                          false,
                                          true,
                                       )}{" "}
                                       :{" "}
                                       {specialDays[dayKey].map((hour, hourIndex, array) => (
                                          <span key={"hour-" + hourIndex}>
                                             {formatJsonTime("2023-01-01T" + hour.startTime)} -{" "}
                                             {formatJsonTime("2023-01-01T" + hour.endTime)}
                                             {hourIndex < array.length - 1 ? " et " : ""}
                                          </span>
                                       ))}
                                    </p>
                                 ))}
                                 <br />
                              </>
                           )}
                           <h4>Horaires habituels</h4>
                           <OpeningHours />
                        </>
                     }
                     size="md"
                     show={showOpeningModal}
                     onCancel={() => setShowOpeningModal(false)}
                  />
               </div>
            )}
            <div className="header__content page-container">
               <NavLink to="/" onClick={() => {
                  window.scrollTo(0, 0)
               }} className="header__logo" onMouseEnter={() => setActiveMenu()}>
                  <img
                     src="/assets/logos/logo_fdtb.png"
                     srcSet="/assets/logos/logo_fdtb@2x.png 2x"
                     alt="logo de la ferme du trèfle blanc"
                  />
               </NavLink>
               <div className={`header__menu ${burgerIsOpen ? "" : "header__menu--closed"}`} onClick={() => setBurgerIsOpen(false)}></div>
               <nav
                  className={`header__nav ${burgerIsOpen ? "header__nav--opened" : "header__nav--closed"
                     }`}
               >
                  <CloseButton className="header__nav-btn-close" onClick={() => setBurgerIsOpen(false)} />
                  <NavLink
                     id="products"
                     to="/produits"
                     onClick={() => setBurgerIsOpen(false)}
                     onMouseEnter={() => setActiveMenu("products")}
                     className={activeMenu === "products" ? "active" : "inactive"}
                  >
                     Produits
                  </NavLink>
                  <NavLink
                     id="about"
                     to="/qui-sommes-nous"
                     onClick={() => setBurgerIsOpen(false)}
                     onMouseEnter={() => setActiveMenu("about")}
                     className={activeMenu === "about" ? "active" : "inactive"}
                  >
                     Qui sommes-nous
                  </NavLink>
                  <NavLink id="news" to="/actualites" onClick={() => setBurgerIsOpen(false)} onMouseEnter={() => setActiveMenu()}>
                     Actualités
                  </NavLink>
                  <NavLink id="contact" to="/contact" onClick={() => setBurgerIsOpen(false)} onMouseEnter={() => setActiveMenu()}>
                     Contact
                  </NavLink>
                  <NavLink
                     id="search"
                     to="/rechercher"
                     onClick={() => setBurgerIsOpen(false)}
                     aria-label="rechercher"
                     onMouseEnter={() => setActiveMenu()}
                     className="header__nav-link"
                  >
                     <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
                     <span>Rechercher</span>
                  </NavLink>
                  <NavLink
                     id="account"
                     to="/compte"
                     onClick={() => setBurgerIsOpen(false)}
                     aria-label="compte utilisateur"
                     onMouseEnter={() => setActiveMenu()}
                     className="header__nav-link"
                  >
                     <FontAwesomeIcon icon={faUser} size="xl" />
                     <span>Mon compte</span>
                  </NavLink>
                  <button
                     className="header__cart header__nav-link"
                     id="cart"
                     onMouseEnter={() => setActiveMenu()}
                     onClick={() => {
                        setBurgerIsOpen(false);
                        if (location.pathname !== "/panier") {
                           setCartModalIsOpen(true);
                        }
                     }}
                  >
                     <FontAwesomeIcon
                        icon={faBasketShopping}
                        size="xl"
                        className={`header__cart-icon${location.pathname === "/panier" ? " header__cart-icon--active" : ""
                           }`}
                     />
                     <span className={location.pathname === "/panier" ? "header__cart-icon--active" : ""}>Mon panier</span>
                     {cartItemsNumber > 0 && (
                        <div className="header__cart-number">{cartItemsNumber}</div>
                     )}
                  </button>
               </nav>
               <nav className="header__burger">
                  <button
                     className="header__cart"
                     onClick={() => {
                        if (location.pathname !== "/panier") {
                           setCartModalIsOpen(true);
                        }
                     }}
                  >
                     <FontAwesomeIcon
                        icon={faBasketShopping}
                        className={`header__cart-icon${location.pathname === "/panier" ? " header__cart-icon--active" : ""
                           }`}
                     />
                     {cartItemsNumber > 0 && (
                        <div className="header__cart-number">{cartItemsNumber}</div>
                     )}
                  </button>

                  <button onClick={() => setBurgerIsOpen(true)} aria-label="menu">
                     <FontAwesomeIcon icon={faBars} />
                  </button>
               </nav>
            </div>
         </div>
         <div
            ref={submenuRef}
            className={`header__submenu ${headerClass} ${activeMenu ? "header__submenu--visible" : "header__submenu--hidden"
               }`}
         >
            {activeMenu === "products" &&
               categories &&
               categories.map((category, index) => (
                  <NavLink
                     key={`submenu-category-${index}`}
                     to={category.url}
                     onClick={() => setActiveMenu()}
                  >
                     {category.title}
                  </NavLink>
               ))}
            {activeMenu === "about" &&
               aboutPages &&
               aboutPages.map((aboutPage, index) => (
                  <NavLink
                     key={`submenu-about-${index}`}
                     to={aboutPage.url}
                     onClick={() => setActiveMenu()}
                  >
                     {aboutPage.title}
                  </NavLink>
               ))}
         </div>
      </header>

   );
}

export default Header;
