import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useContext, useMemo, Fragment } from "react";
import { UserContext } from "../../contexts/userContext";
import { SettingsContext } from "../../contexts/settingsContext";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import ImageSelector from "../ImageSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";
import Select, { components } from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const NewValueContainer = (props) => {
   return (
      <components.ValueContainer {...props}>
         <div className="react-select__floating-label">{props.selectProps.floatingLabel}</div>
         {props.children}
      </components.ValueContainer>
   );
};

function EditProductModal({ show, onClose, onRefresh, product, category }) {
   const [name, setName] = useState("");
   const [variants, setVariants] = useState([]);
   const [description, setDescription] = useState("");
   const [categorySelected, setCategorySelected] = useState();
   const [subcategorySelected, setSubcategorySelected] = useState(null);
   const [ingredientsSelected, setIngredientsSelected] = useState(null);
   const [allergensSelected, setAllergensSelected] = useState(null);
   const [recipe, setRecipe] = useState("");
   const [enabled, setEnabled] = useState(true);
   const [highlighted, setHighlighted] = useState(false);
   const [onlyInStore, setOnlyInStore] = useState(false);
   const [organic, setOrganic] = useState(true);
   const [showMeasurement, setShowMeasurement] = useState(false);
   const [approximativeMeasurement, setApproximativeMeasurement] = useState(false);
   const [selectedFile, setSelectedFile] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState();
   const [successMessage, setSuccessMessage] = useState();
   const { user } = useContext(UserContext);
   const { categories, ingredients, allergens } = useContext(SettingsContext);

   useEffect(() => {
      setCategorySelected(category);
      if (product && product.ProductSubcategory) {
         setSubcategorySelected(product.ProductSubcategory);
      } else {
         setSubcategorySelected(null);
      }
      if (product && product.Ingredients) {
         setIngredientsSelected(product.Ingredients);
      } else {
         setIngredientsSelected(null);
      }
      if (product && product.Allergens) {
         setAllergensSelected(product.Allergens);
      } else {
         setAllergensSelected(null);
      }
      setName(product ? product.title : "");

      let array = [];
      if (product) {
         array = [
            ...product.ProductVariants.map((item) => {
               return {
                  price: item.price,
                  measurementValue: item.measurementValue,
                  measurementUnit: { id: item.measurementUnit, title: item.measurementUnit },
               };
            }),
         ];
         array.unshift({
            price: product.price,
            measurementUnit: { id: product.measurementUnit, title: product.measurementUnit },
            measurementValue: product.measurementValue,
         });
      } else {
         array.push({
            price: "",
            measurementUnit: { id: "g", title: "g" },
            measurementValue: "",
         });
      }

      setVariants(array);
      setDescription(product && product.description ? product.description : "");
      setRecipe(product && product.recipe ? product.recipe : "");
      setEnabled(product ? product.enabled : true);
      setHighlighted(product ? product.highlighted : false);
      setOnlyInStore(product ? product.onlyInStore : false);
      setOrganic(product ? product.organic : true);
      setShowMeasurement(product ? product.showMeasurement : false);
      setApproximativeMeasurement(product ? product.approximativeMeasurement : false);
      setSelectedFile();
      setErrorMessage();
      setSuccessMessage();
   }, [product, show, category]);

   useEffect(() => {
      if (variants.length > 1) {
         setShowMeasurement(true);
      }
   }, [variants]);

   const handleSubmit = (e) => {
      e.preventDefault();

      if (selectedFile && selectedFile.size > 30485760) {
         setErrorMessage("Merci de choisir une photo d'une taille inférieure à 30 Mo");
         return;
      }

      if (!subcategorySelected) {
         setErrorMessage("Merci de sélectionner une sous-catégorie");
         return;
      }

      setIsLoading(true);
      const sendData = async () => {
         if (!user) {
            return;
         }

         try {
            let formData = new FormData();
            const productData = {
               title: name,
               measurementValue: parseInt(variants[0].measurementValue),
               measurementUnit: variants[0].measurementUnit.id,
               price: parseFloat(variants[0].price),
               description: description,
               recipe: recipe,
               enabled: enabled,
               highlighted: highlighted,
               onlyInStore: onlyInStore,
               organic: organic,
               showMeasurement: showMeasurement,
               approximativeMeasurement: approximativeMeasurement,
               ProductCategoryId: categorySelected.id,
               ProductSubcategoryId: subcategorySelected.id,
               Ingredients: ingredientsSelected,
               Allergens: allergensSelected,
            };
            if (variants.length > 1) {
               productData.ProductVariants = variants.slice(1).map((item) => {
                  return { ...item, measurementUnit: item.measurementUnit.id };
               });
            }
            if (selectedFile === null || selectedFile) {
               productData.destroyImage = true;
            }

            formData.append("product", JSON.stringify(productData));
            if (selectedFile) {
               formData.append("image", selectedFile);
            }

            const url =
               process.env.REACT_APP_API_URL +
               (product ? "/api/products/" + product.id : "/api/products");
            const method = product ? "PUT" : "POST";

            const response = await fetch(url, {
               method: method,
               body: formData,
               credentials: 'include',
            });
            if (response.status === 201) {
               setSuccessMessage("Produit bien créé");
               onRefresh();
            } else if (response.status === 200) {
               setSuccessMessage("Produit bien modifié");
               onRefresh();
            } else {
               setErrorMessage(`Erreur, le produit ne peut pas être ${product ? "modifié" : "créé"}`);
            }
         } catch (error) {
            setErrorMessage(error);
         } finally {
            setIsLoading(false);
         }
      };
      sendData();
   };

   const addVariant = () => {
      const copy = Array.from(variants);
      const unit = copy[0].measurementUnit;
      copy.push({ price: "", measurementUnit: unit, measurementValue: "" });
      setVariants(copy);
   };

   const removeVariant = (index) => {
      const copy = Array.from(variants);
      copy.splice(index, 1);
      setVariants(copy);
   };

   const modifyVariant = (index, key, value) => {
      const copy = Array.from(variants);
      copy[index][key] = value;
      setVariants(copy);
   };

   const allSubcategories = useMemo(() => {
      if (categorySelected && categories) {
         const eligible = categories.find((item) => item.id === categorySelected.id);
         return eligible ? eligible.ProductSubcategories : [];
      }
      return [];
   }, [categorySelected, categories]);

   return (
      <Modal
         size="xl"
         show={show}
         fullscreen="sm-down"
         aria-labelledby="edit-modal-title"
         centered
         className="edit-modal edit-product-modal"
      >
         <Modal.Body>
            <h2 id="edit-modal-title">
               {product ? "Modifier un produit" : "Ajouter un nouveau produit"}
            </h2>

            {successMessage ? (
               <div className="edit-modal__success">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  {successMessage}
               </div>
            ) : (
               <Form onSubmit={handleSubmit} id="edit-modal-form">
                  <div className="edit-modal__columns">
                     <div>
                        <h3>Informations générales</h3>
                        <div className="edit-modal__grid">
                           <FloatingLabel
                              controlId="title"
                              label="Nom du produit *"
                              className="edit-modal__colspan"
                           >
                              <Form.Control
                                 type="text"
                                 value={name}
                                 onChange={(e) => {
                                    setName(e.target.value);
                                    setErrorMessage();
                                 }}
                                 required
                                 disabled={isLoading}
                              />
                           </FloatingLabel>

                           {variants.map((variant, index, array) => (
                              <Fragment key={`variant-${index}`}>
                                 <div className="edit-product-modal__two-columns">
                                    <FloatingLabel
                                       controlId={`measurementValue${index}`}
                                       label={
                                          variant.measurementUnit.id === "g"
                                             ? "Poids *"
                                             : (variant.measurementUnit.id === "ml" ? "Volume *" : "Parts *")
                                       }
                                    >
                                       <Form.Control
                                          type="number"
                                          min="0"
                                          value={variant.measurementValue}
                                          onChange={(e) => {
                                             modifyVariant(
                                                index,
                                                "measurementValue",
                                                e.target.value,
                                             );
                                             setErrorMessage();
                                          }}
                                          disabled={isLoading}
                                          required
                                       />
                                    </FloatingLabel>
                                    <Select
                                       isDisabled={isLoading}
                                       options={[
                                          { id: "g", title: "g" },
                                          { id: "ml", title: "ml" },
                                          { id: "p", title: "p" },
                                       ]}
                                       value={variant.measurementUnit}
                                       onChange={(selectedOption) => {
                                          //setMeasurementUnit(selectedOption);
                                          modifyVariant(index, "measurementUnit", selectedOption);
                                       }}
                                       getOptionLabel={(option) => option.title}
                                       getOptionValue={(option) => option.id}
                                       className="react-select-container"
                                       classNamePrefix="react-select"
                                       placeholder=""
                                       floatingLabel="Unité *"
                                       components={{ ValueContainer: NewValueContainer }}
                                    />
                                 </div>
                                 <div className="edit-product-modal__two-columns">
                                    <FloatingLabel controlId={`price${index}`} label="Prix *">
                                       <Form.Control
                                          type="number"
                                          min="0"
                                          step="0.001"
                                          value={variant.price}
                                          onChange={(e) => {
                                             modifyVariant(index, "price", e.target.value);
                                             setErrorMessage();
                                          }}
                                          required
                                          disabled={isLoading}
                                       />
                                    </FloatingLabel>

                                    {index === array.length - 1 && (
                                       <Button
                                          variant="secondary"
                                          size="sm"
                                          onClick={() => addVariant()}
                                       >
                                          + variante
                                       </Button>
                                    )}
                                    {array.length > 1 && (
                                       <Button
                                          variant="secondary"
                                          size="sm"
                                          onClick={() => removeVariant(index)}
                                       >
                                          <FontAwesomeIcon icon={faTrash} />
                                       </Button>
                                    )}
                                 </div>
                              </Fragment>
                           ))}

                           <FloatingLabel
                              controlId="description"
                              label="Description *"
                              className="edit-modal__colspan"
                           >
                              <Form.Control
                                 as="textarea"
                                 value={description}
                                 onChange={(e) => {
                                    setDescription(e.target.value);
                                    setErrorMessage();
                                 }}
                                 required
                                 disabled={isLoading}
                              />
                           </FloatingLabel>

                           <FloatingLabel
                              controlId="recipe"
                              label="Idée recette"
                              className="edit-modal__colspan"
                           >
                              <Form.Control
                                 as="textarea"
                                 value={recipe}
                                 onChange={(e) => {
                                    setRecipe(e.target.value);
                                    setErrorMessage();
                                 }}
                                 disabled={isLoading}
                              />
                           </FloatingLabel>

                           <Select
                              isDisabled={isLoading}
                              options={categories}
                              value={categorySelected}
                              onChange={(selectedOption) => {
                                 setSubcategorySelected(null);
                                 setCategorySelected(selectedOption);
                              }}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.id}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Aucune catégorie trouvée"}
                              placeholder=""
                              floatingLabel="Catégorie *"
                              components={{ ValueContainer: NewValueContainer }}
                           />
                           <CreatableSelect
                              isClearable
                              maxMenuHeight={180}
                              isDisabled={isLoading}
                              value={subcategorySelected}
                              options={allSubcategories}
                              onChange={(selectedOption) => {
                                 setSubcategorySelected(selectedOption);
                                 setErrorMessage();
                              }}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.id}
                              getNewOptionData={(inputValue, optionLabel) => ({
                                 id: inputValue,
                                 title: optionLabel,
                              })}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              formatCreateLabel={(input) => `Créer "${input}"`}
                              noOptionsMessage={() => "Aucune sous-catégorie trouvée"}
                              placeholder=""
                              floatingLabel="Sous-catégorie *"
                              components={{ ValueContainer: NewValueContainer }}
                           />

                           <CreatableSelect
                              isMulti
                              isClearable
                              maxMenuHeight={180}
                              isDisabled={isLoading}
                              value={ingredientsSelected}
                              options={ingredients}
                              onChange={(selectedOptions) => {
                                 setIngredientsSelected(selectedOptions);
                              }}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.id}
                              getNewOptionData={(inputValue, optionLabel) => ({
                                 id: inputValue,
                                 title: optionLabel,
                              })}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              formatCreateLabel={(input) => `Créer "${input}"`}
                              noOptionsMessage={() => "Aucun ingrédient trouvé"}
                              placeholder=""
                              floatingLabel="Ingrédients"
                              components={{ ValueContainer: NewValueContainer }}
                           />
                           <CreatableSelect
                              isMulti
                              isClearable
                              maxMenuHeight={180}
                              isDisabled={isLoading}
                              value={allergensSelected}
                              options={allergens}
                              onChange={(selectedOptions) => {
                                 setAllergensSelected(selectedOptions);
                              }}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.id}
                              getNewOptionData={(inputValue, optionLabel) => ({
                                 id: inputValue,
                                 title: optionLabel,
                              })}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              formatCreateLabel={(input) => `Créer "${input}"`}
                              noOptionsMessage={() => "Aucun allergène trouvé"}
                              placeholder=""
                              floatingLabel="Allergènes"
                              components={{ ValueContainer: NewValueContainer }}
                           />
                        </div>
                     </div>
                     <div>
                        <h3>Photo</h3>
                        <ImageSelector
                           imageUrl={
                              product && product.imageUrl.find((image) => image.fallback).url
                           }
                           onChange={(newFile) => {
                              setSelectedFile(newFile);
                              setErrorMessage();
                           }}
                           selectorId="input1"
                        />
                     </div>
                  </div>
                  <div>
                     <h3>Paramètres</h3>
                     <div className="edit-product-modal__three-columns">
                        <div>
                           <Form.Check
                              type="switch"
                              id="enabled"
                              label="Activé"
                              checked={enabled}
                              onChange={(e) => {
                                 setEnabled(e.target.checked);
                                 setErrorMessage();
                              }}
                              disabled={isLoading}
                           />
                           <Form.Check
                              type="switch"
                              id="onlyInStore"
                              label="Disponible uniquement en magasin"
                              checked={onlyInStore}
                              onChange={(e) => {
                                 setOnlyInStore(e.target.checked);
                                 setErrorMessage();
                              }}
                              disabled={isLoading}
                           />
                        </div>
                        <div>
                           <Form.Check
                              type="switch"
                              id="organic"
                              label="Certifié biologique"
                              checked={organic}
                              onChange={(e) => {
                                 setOrganic(e.target.checked);
                                 setErrorMessage();
                              }}
                              disabled={isLoading}
                           />
                           <Form.Check
                              type="switch"
                              id="highlighted"
                              label="Mis en avant dans les nouveautés"
                              checked={highlighted}
                              onChange={(e) => {
                                 setHighlighted(e.target.checked);
                                 setErrorMessage();
                              }}
                              disabled={isLoading}
                           />
                        </div>
                        <div>
                           <Form.Check
                              type="switch"
                              id="showMeasurement"
                              label="Afficher le poids partout"
                              checked={showMeasurement}
                              onChange={(e) => {
                                 setShowMeasurement(e.target.checked);
                                 setErrorMessage();
                              }}
                              disabled={isLoading || variants.length > 1}
                           />
                           <Form.Check
                              type="switch"
                              id="approximativeMeasurement"
                              label="Poids approximatif"
                              checked={approximativeMeasurement}
                              onChange={(e) => {
                                 setApproximativeMeasurement(e.target.checked);
                                 setErrorMessage();
                              }}
                              disabled={isLoading}
                           />
                        </div>
                     </div>
                  </div>
                  {errorMessage && errorMessage.length > 0 && (
                     <ErrorMessage>{errorMessage}</ErrorMessage>
                  )}
               </Form>
            )}
         </Modal.Body>
         <Modal.Footer>
            {isLoading ? (
               <Loader />
            ) : (
               <>
                  <Button className="btn-rounded" onClick={onClose} variant="secondary">
                     Fermer
                  </Button>

                  {!successMessage && (
                     <Button
                        className="btn-rounded"
                        variant="primary"
                        form="edit-modal-form"
                        type="submit"
                     >
                        Valider
                     </Button>
                  )}
               </>
            )}
         </Modal.Footer>
      </Modal>
   );
}

export default EditProductModal;
