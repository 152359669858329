/**
 * Composant affichant un formulaire d'abonnement à la newsletter
 * @return JSX.element
 */

import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";

function Newsletter() {

    const emailField = useRef();
    const [success, setSuccess] = useState(0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        /*if (!user) {
            return;
        }
        if (searchInput.length === 0) {
            setUsers(null);
            return;
        }
        const query = encodeURI(searchInput);
        const { success, data } = await sendDataAPI(`users/?search=${query}`, "GET", null, true);
        if (success) {
            setUsers(data);
        }*/

        try {
            const emailEnc = encodeURIComponent(emailField.current.value);
            const response = await fetch("https://www.fermedutrefleblanc.fr/newsletter/?p=asubscribe&id=1", {
                method: "POST",
                body: `email=${emailEnc}&htmlemail=1&list%5B4%5D=signup&subscribe=subscribe`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            });
            if (response.status === 200) {
                setSuccess(1);
            } else {
                setSuccess(2);
            }
        } catch {
            setSuccess(2);
        }

    };

    return (
       <div className="newsletter">
            <FontAwesomeIcon icon={faEnvelope} />
            <h2>Abonnez-vous à notre newsletter</h2>
            <div className="newsletter__container">
                {success === 0 &&
                <Form onSubmit={handleSubmit}>
                    <div className="newsletter__field-container">
                        <Form.Control ref={emailField} className="newsletter__field" type="email" autoCapitalize="off" placeholder="Votre adresse email" required />
                        <Button variant="secondary" type="submit">OK</Button>
                    </div>
                </Form>}
                {success === 1 && "Nous vous avons envoyé un message pour confirmer votre adresse. Merci de cliquer sur le lien contenu dans ce message."}
                {success === 2 && "Inscription impossible, merci de nous contacter."}
            </div>
       </div>
    );
}
 
export default Newsletter;